import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import surveyData from './survey.json';
import { FunctionComponent } from 'react';

const SurveyComponent: FunctionComponent = () => {
  const survey = new Model(surveyData);

  const surveyId = new URLSearchParams(document.location.search).get('survey_id');
  if (surveyId == null) {
    survey.mode = 'display';
    survey.showNavigationButtons = false;

    survey.setVariable('errorText', 'The survey ID not provided');

    survey.getPageByName('welcome').visible = false;
    survey.getPageByName('error').visible = true;
  } else {
    survey.mode = 'display';

    fetch(`/survey/${surveyId}`).then(async (response) => {
      if (response.status === 200) {
        survey.mode = 'edit';

        survey.getPageByName('user').visible = true;
        survey.getPageByName('questions').visible = true;
        survey.getPageByName('welcome').visible = false;
      } else {
        survey.showNavigationButtons = false;

        const data = await response.json();

        survey.setVariable('errorText', 'error' in data ? data['error'] : 'Unknown error');

        survey.getPageByName('welcome').visible = false;
        survey.getPageByName('error').visible = true;
      }
    });

    survey.onComplete.add((sender, options) => {
      options.showSaveInProgress();

      fetch(`/survey/${surveyId}`, { method: 'POST', body: JSON.stringify(sender.data) }).then(async (response) => {
        if (response.status === 200) {
          options.showSaveSuccess();
        } else {
          const data = await response.json();

          options.showSaveError('error' in data ? data['error'] : 'Unknown error');
        }
      });
    });
  }

  return <Survey model={survey} />;
};

export default SurveyComponent;
